import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import arrowSvg from "../images/arrow-circle-right-solid.svg"
import liveTrackingImg from "../images/live-tracking-sq-small.jpg"

class HGS1ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="HGS2"
          description="View route information and buy your ticket for the HGS2 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">HGS2 service</h1>
              <p>All bus stops between Savins Mill Way – Armley Gyratory, then runs non-stop to Heckmondwike Grammar</p>
              <p>Heckmondwike Grammar - All bus stops between Armley Gyratory - Commercial Road, Leeds</p>
              <p style={{ margin: 0 }}>
                <a className='btn btn-grow btn-quote btn-ticket' href="https://passenger.shuttleid.uk/client/3108be57-5c93-4d16-84ff-0990d87eba95/2353295e-7d60-4c42-8f0d-02ffb44a721c">
                  <span className='btn-quote__text'>Buy ticket</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
                <a
                  style={{ display: "inline-block", marginLeft: 15 }}
                  href="https://passenger.shuttleid.uk"
                >
                  View My Tickets
                </a>
              </p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <p style={{ margin: 0 }}>
                  <img src={liveTrackingImg} alt="" />
                </p>
              </div>
              <div className="page-content__text">
                <h2 style={{ marginBottom: 15 }}>NEW: Live vehicle tracking</h2>
                <p>Users of the HGS2 service will be able to access live vehicle tracking directly from their ticket, starting January 2022.</p>
                <p>Our new "track bus" feature will allow students and parents to stay informed in real-time, and will complement our existing SMS service alerts, to offer the safest travel experience possible for your child.</p>
              </div>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="HGS2 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1OZrtKBlYUB-0M79Z-oxlCIsXTzgB2rY&ehbc=2E312F"
                  allowfullscreen=""
                  loading="lazy"
                  width="100%"
                  height="480"></iframe>
                <p>
                  <strong>Towards</strong> Heckmondwike grammar school, Wakefield
                </p>
                <p>
                  Savins Mill way (Bus stop by Kirkstall morrisons), (R) Bridge Road, (L) Abbey Road A65, New Road Side, (R) Ring Road A6120, (L) King Lane, U Turn at Alwoodley park and Ride, King Lane, (L) Stonegate road, (R) Street Lane, (R) Harrogate Road, (L) Lidgett Lane, Gledhow Lane, (R) Roundhay Road, Clay pit lane,  Inner Ring Road, (L) Domestic Street, (R) Domestic Road, (L) Geldard Road, (L) A6110, M621, Geldard Road, None stop to  Heckmondwike Grammar School
                </p>
                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="HGS2 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1B8Gcv7MlbUCzoeBSZ323Hd7SlQxxqkA&ehbc=2E312F"
                  allowfullscreen=""
                  loading="lazy"
                  width="100%"
                  height="480"></iframe>
                <p>
                  <strong>From</strong> Heckmondwike grammar school, Wakefield
                </p>
                <p>
                  From Heckmondwike Grammar School None Stop to, Geldard Road, M621, A6610, Inner Ring Road, Clay Pit Lane, Roundhay Road, Oakwood Lane, (opposite Mcdonalds) Roundhay Road, Gledhow Lane, Lidgett Lane, Harrogate Road, Street Lane, Stonegate Road, King Lane, U turn @Alwoodley park and ride, King Lane, Ring Road (West), New road side, Abbey Road A65, Commercial Road.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Kirkstall Morrison's</td>
                      <td>DEP</td>
                      <td>0700</td>
                    </tr>
                    <tr>
                      <td>Ring road at Fink Hill</td>
                      <td>DEP</td>
                      <td>0708</td>
                    </tr>
                    <tr>
                      <td>Alwoodley Park and Ride</td>
                      <td>DEP</td>
                      <td>0723</td>
                    </tr>
                    <tr>
                      <td>Oakwood Clock</td>
                      <td>DEP</td>
                      <td>0735</td>
                    </tr>
                    <tr>
                      <td>Salford Van Hire</td>
                      <td>DEP</td>
                      <td>0745</td>
                    </tr>
                    <tr>
                      <td>Birstall</td>
                      <td>DEP</td>
                      <td>0800</td>
                    </tr>
                    <tr>
                      <td>Heckmondwike Grammar</td>
                      <td>ARR</td>
                      <td>0835</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Heckmondwike Grammar</td>
                      <td>DEP</td>
                      <td>1530</td>
                    </tr>
                    <tr>
                      <td>Oakwood Lane (Opposite McDonalds)</td>
                      <td>DEP</td>
                      <td>1600</td>
                    </tr>
                    <tr>
                      <td>Lidgett Lane (LS8 1LW)</td>
                      <td>DEP</td>
                      <td>1610</td>
                    </tr>
                    <tr>
                      <td>Alwoodley Park and ride</td>
                      <td>DEP</td>
                      <td>1620</td>
                    </tr>
                    <tr>
                      <td>Ring Road at Fink hill</td>
                      <td>DEP</td>
                      <td>1635</td>
                    </tr>
                    <tr>
                      <td>Commercial Road stop ID 45010925</td>
                      <td>ARR</td>
                      <td>1645</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default HGS1ServicePage
